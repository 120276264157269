
import { Component, Vue } from "vue-property-decorator";
import AdminTable from "@/components/admin/AdminTable.vue";

@Component({
  components: {
    AdminTable
  }
})
export default class AdminSettings extends Vue {
  columns = [
    {
      name: "_id",
      required: true,
      label: "_id",
      field: "_id",
      align: "left",
      type: "string"
    },
    {
      name: "key",
      align: "center",
      label: "key",
      field: "key",
      editable: false,
      type: "string"
    },
    {
      name: "value",
      align: "center",
      label: "value",
      field: "value",
      editable: true,
      type: "number"
    },
  ];

}
